/**
 * Represents a slot. Can either be a googletag.Slot object, or a containerId
 */
export type SlotUnion = string | googletag.Slot;

/**
 * Function executed when all bids have returned from bidders
 */
export type BidBackHandler = (bids: any[]) => void;

/**
 * Callback for when auctions complete
 */
export type AuctionCompleteHandler = (slots: googletag.Slot[]) => void;

/**
 * Valid values for ad platform names
 */
export type AuctionAdapterName = "pbjs" | "amazon";

/**
 * Config object for prebid auction adapter.
 */
export interface PrebidAdapterConfig {
  /**
   * Function executed when all bids have returned from bidders
   */
  bidsBackHandler: BidBackHandler;
}

/**
 * Config object for amazon auction adapter.
 */
export interface AmazonAdapterConfig {}

/**
 * Configuration object for auctions
 */
export interface AuctionConfig {
  pbjs: PrebidAdapterConfig;
  amazon: AmazonAdapterConfig;
  adapters: AuctionAdapterName[];
  /**
   * Executed when the auction is complete. If this is provided, the auction will
   * not automatically refresh the ad slots.
   */
  onCompleteHandler: AuctionCompleteHandler;

  /**
   * Deprecated. use onCompleteHandler instead
   */
  bidsBackHandler: AuctionCompleteHandler;
}

export interface DestroySlotsOptions {
  clearRefresh?: boolean;
  removeFromDom?: boolean;
}

export interface MastodonOptions {
  /**
   * Cross domain token for use with CMP cross-domain consent
   */
  crossDomainToken?: string;
  /**
   * the userId to use for CMP cross-domain consent
   */
  userId?: string;
  /**
   * A full config object for the mastodon library. If specified, Mastdon will not
   * fetch the config from the server.
   */
  config?: any;

  // normalized email address encoded with MD5 in hexadecimal
  emailMD5?: string;

  // normalized email address encoded with SHA256 in hexadecimal
  emailSHA256?: string;

  // normalized email address encoded with SHA1 in hexadecimal
  emailSHA1?: string;
}

export interface GeoData {
  countryCode: string;
  stateCode: string;
  countryName?: string;
  stateName?: string;
  postalCode?: string;
  timezone?: string;
  city?: string;
  source?: string;
}
