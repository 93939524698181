import AdRefreshManager from "./AdRefreshManager";
import ConfigManager from "./ConfigManager";
import googletag from "./googletag";
import injectScript from "./injectScript";
import Logger from "./Logger";
import TargetingManager from "./TargetingManager";
import { SlotUnion } from "./Types";
import { getSlotContainerIDs, getSlotContainerID } from "./Utils";
import { debounce } from "throttle-debounce";
class GoogletagManager {
  containerSlotMap: Object = {};
  renderListeners = [];

  init() {
    if (!ConfigManager.hasAds()) {
      Logger.log("Not loading GPT because ads are not enabled");
      return;
    }
    injectScript("https://securepubads.g.doubleclick.net/tag/js/gpt.js", {
      async: true,
      crossorigin: "anonymous",
    });

    this.do(() => {
      googletag()
        .pubads()
        .addEventListener("slotRenderEnded", (event) => {
          this.onSlotRendered(event);
        });
    });
  }

  do(func) {
    googletag().cmd.push(func);
  }

  getSlot(containerId: string) {
    return this.containerSlotMap[containerId]?.slot;
  }

  getSlotContainerId(slot: googletag.Slot) {
    return slot.getSlotId().getDomId();
  }

  getAdUnitCodeForSlot(containerId: string) {
    return this.containerSlotMap[containerId]?.adUnitPath;
  }

  setGlobalTargeting() {
    const t = TargetingManager.getGlobalTargeting();
    Logger.log("Setting global targeting", t);
    Object.keys(t).forEach((k) => {
      Logger.log(`Global key ${k}:${t[k]}`);
      googletag().pubads().setTargeting(k, String(t[k]));
    });
  }

  /**
   * Instructs the service to render the slot
   * @param slotId
   */
  display(slot: SlotUnion) {
    const container = getSlotContainerID(slot);
    if (ConfigManager.isContainerDisabled(container)) {
      Logger.log("display: Skipping disabled adunit", container);
      return;
    }
    Logger.log("setting display for ", slot, container);
    googletag().cmd.push(() => {
      googletag().display(slot);
    });
  }

  setSlotTargeting(slots?: SlotUnion[]) {
    let slotContainers;
    if (slots) {
      slotContainers = getSlotContainerIDs(slots);
    } else {
      slotContainers = Object.keys(this.containerSlotMap);
    }
    Logger.log("setSlotTargeting called", this.containerSlotMap);

    slotContainers.forEach((slotName) => {
      const t = TargetingManager.getSlotTargeting(slotName);
      Logger.log("Setting slot targeting for ", slotName, t);
      if (t) {
        Object.keys(t).forEach((key) => {
          this.containerSlotMap[slotName].slot.setTargeting(
            key,
            String(t[key])
          );
        });
      }
    });
  }

  addSlot(adUnit: string, containerId: string): Promise<googletag.Slot> {
    Logger.log(`adding slot for ${adUnit} to container ${containerId}`);

    if (ConfigManager.isAdunitDisabled(adUnit)) {
      Logger.log("skipping disabled adUnit", adUnit);
      return;
    }
    ConfigManager.addAdUnitToContainerMapping(adUnit, containerId);

    if (!ConfigManager.hasAdUnit(adUnit)) {
      Logger.error(`adUnit '${adUnit}' does not exist in the config.`);
      return;
    }
    const promise = new Promise<googletag.Slot>((resolve, reject) => {
      this.do(() => {
        const sizes = ConfigManager.getContainerSize(containerId);
        Logger.log("addSlot: got filtered sizes ", adUnit, containerId, sizes);
        const adUnitPath = ConfigManager.getAdUnitPath(adUnit);
        if (this.containerSlotMap[containerId]) {
          Logger.log("Slot on this container already exists. Destroying.");
          googletag().destroySlots([this.containerSlotMap[containerId].slot]);
          delete this.containerSlotMap[containerId];
        }
        const slot = googletag()
          .defineSlot(adUnitPath, sizes, containerId)
          .setCollapseEmptyDiv(false, false)
          .addService(googletag().pubads());
        googletag().pubads().enableSingleRequest();
        googletag().enableServices();
        this.containerSlotMap[containerId] = { slot, adUnitPath };
        resolve(slot);
      });
    });
    return promise;
  }

  onSlotRendered(event: googletag.events.SlotRenderEndedEvent) {
    const { slot, isEmpty } = event;
    const containerId = this.getSlotContainerId(slot);

    if (isEmpty) {
      //Logger.log("## Slot was empty", containerId, isEmpty);
      const adunit = ConfigManager.containerIdToAdUnitMap.get(
        this.getSlotContainerId(slot)
      );
      const multiplier = ConfigManager.getNoFillRefreshMultiplier(adunit);
      //Logger.log("## multiplier", multiplier, adunit, containerId);
      AdRefreshManager.multiplyTimerBy(containerId, multiplier);
    } else {
      AdRefreshManager.resetRefreshTimeout(containerId);
    }

    for (var callback of this.renderListeners) {
      callback(slot, isEmpty);
    }
  }

  addRenderListener(callback: (slot, isEmpty) => void) {
    this.renderListeners.push(callback);
  }
}

export default new GoogletagManager();
