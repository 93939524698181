import Logger from "./Logger";
import { SlotUnion } from "./Types";
import { getSlotContainerID } from "./Utils";

class AdRefreshManager {
  slotTimerMap: Map<string, SlotRefreshTimer> = new Map();

  gracePeriodTimer: NodeJS.Timeout = null;
  gracePeriodStarted: boolean = false;
  completedTimers: Array<SlotRefreshTimer> = [];

  addSlot(slot: SlotUnion, timeoutSeconds: number) {
    const containerID = getSlotContainerID(slot);
    if (this.slotTimerMap.has(containerID)) {
      this.removeSlot(slot);
    }

    Logger.log("Adding slot for refresh", containerID, timeoutSeconds);

    const st = new SlotRefreshTimer(containerID, timeoutSeconds);
    this.slotTimerMap.set(containerID, st);
  }

  removeSlot(slot: SlotUnion) {
    const containerID = getSlotContainerID(slot);
    Logger.log("Removing refresh timer for slot", containerID);

    if (this.slotTimerMap.has(containerID)) {
      this.slotTimerMap.get(containerID).destruct();
      this.slotTimerMap.delete(containerID);
      if (this.gracePeriodStarted) {
        this.completedTimers = this.completedTimers.filter(
          (t) => t.slot != containerID
        );
      }
      Logger.log("Refresh timers after clearing", slot, this.slotTimerMap);
    }
  }

  removeAll() {
    [...this.slotTimerMap.keys()].forEach((k) => this.removeSlot(k));
  }

  slotTimedOut(slotTimer: SlotRefreshTimer) {
    this.completedTimers.push(slotTimer);
    if (!this.gracePeriodStarted) {
      // start grace period timer to batch all adunits that timed out in the same second
      Logger.log("Starting refresh grace timer", this.completedTimers);
      this.gracePeriodTimer = setTimeout(
        () => this.gracePeriodTimedOut(),
        1000
      );
      this.gracePeriodStarted = true;
    }
  }

  gracePeriodTimedOut() {
    Logger.log("Ad refresh grace period expired", this.completedTimers);
    this.gracePeriodStarted = false;
    const auctionSlots: Array<string> = this.completedTimers.map(
      (srt: SlotRefreshTimer) => srt.slot
    );
    this.completedTimers.forEach((srt: SlotRefreshTimer) => srt.restartTimer());
    this.completedTimers = [];
    Logger.log("Refresh slots: ", auctionSlots);

    // start auction for all slots that timed out
    if (!Array.isArray(window.Mastodon)) {
      window.Mastodon.loadAds(auctionSlots);
    }
  }

  multiplyTimerBy(containerId: string, multiplier: number) {
    const timer = this.slotTimerMap.get(containerId);
    if (timer) {
      Logger.log(
        `Multiplying existing restart timer for ${containerId} by ${multiplier} to ${
          timer.timeoutSeconds * multiplier
        }`
      );

      timer.timeoutSeconds *= multiplier;
      if (!timer.hasTimedOut) timer.restartTimer();
    }
  }

  resetRefreshTimeout(containerId: string) {
    const timer = this.slotTimerMap.get(containerId);
    if (timer && timer.timeoutSeconds != timer.baseTimeoutSeconds) {
      Logger.log(
        `Resetting refresh timeout from ${timer.timeoutSeconds} to ${timer.baseTimeoutSeconds} since got fill`
      );
      timer.timeoutSeconds = timer.baseTimeoutSeconds;
      timer.restartTimer();
    }
  }
}

const _adRefreshManager = new AdRefreshManager();

class SlotRefreshTimer {
  slot: string;
  timer: NodeJS.Timeout;
  timeoutSeconds: number;
  hasTimedOut: boolean = false;
  baseTimeoutSeconds: number;

  constructor(slot, timeoutSeconds) {
    this.slot = slot;
    this.timeoutSeconds = timeoutSeconds;
    this.baseTimeoutSeconds = timeoutSeconds;
    this.restartTimer();
  }

  onTimedOut() {
    Logger.log("Time for slot refresh", this);
    this.hasTimedOut = true;
    _adRefreshManager.slotTimedOut(this);
  }

  restartTimer() {
    this.hasTimedOut = false;
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(
      () => this.onTimedOut(),
      (this.timeoutSeconds - 1) * 1000 // subtracting 1 second to allow for grace period second
    );
  }

  destruct() {
    clearTimeout(this.timer);
  }
}

export default _adRefreshManager;
