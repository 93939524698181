import GoogletagManager from "./GoogletagManager";
import DomAdManager from "./DomAdManager";

import Logger from "./Logger";
import { AdRenderedEvent, SkipLazyLoadAdSlotEvent } from "./Events";

export class LazyLoadElement {
  element: HTMLElement;
  isVisible: boolean;
  renderCompleted: boolean = false;
  isFilled: boolean = false;
  canTryRender: boolean = false;

  constructor(element) {
    this.element = element;
  }
}

class LazyLoadAdManager {
  elementMap: Map<string, LazyLoadElement> = new Map();
  elementList: Array<LazyLoadElement> = [];
  observer: IntersectionObserver;
  elementSkipCount = 1;
  // the index of the lazy load ad we are trying to fill now
  tryFillIndex = 0;
  isLoadingAd = false;

  constructor() {
    this.observer = new window.IntersectionObserver(
      (entries: IntersectionObserverEntry[], observer: IntersectionObserver) =>
        this.onIntersectionObserved(entries, observer),
      {
        root: null,
        rootMargin: `${window.innerHeight}px`, // use viewport height as margin
        threshold: 0.75,
      }
    );
    GoogletagManager.addRenderListener(
      (slot: googletag.Slot, isEmpty: boolean) =>
        this.onAdRendered(slot, isEmpty)
    );
  }

  onAdRendered(slot: googletag.Slot, isEmpty: boolean) {
    const slotId = slot.getSlotElementId();
    if (!Array.isArray(window.Mastodon)) {
      Logger.log("## dispatching adRendered event", slotId, isEmpty, slot);
      window.Mastodon.dispatchEvent(new AdRenderedEvent(slot, slotId, isEmpty));
    }
    const lle = this.elementList.find((e) => e.element.id == slotId);
    if (!lle) return;

    lle.renderCompleted = true;
    this.isLoadingAd = false;
    if (isEmpty) {
      // skip the next [skipCount] element due to no load

      lle.isFilled = false;

      Logger.log(
        `## lazy ad ${slotId} did not fill`,
        lle,
        this.tryFillIndex,
        this.elementList.length
      );
      for (let i = 0; i < this.elementSkipCount; i++) {
        const skipIndex = this.tryFillIndex + 1 + i;
        if (skipIndex >= this.elementList.length) break;
        const skippedElement = this.elementList[skipIndex];

        if (!Array.isArray(window.Mastodon)) {
          Logger.log(
            `## Skipping lazy load element ${skippedElement.element.id} due to no fill`,
            skipIndex,
            this.elementSkipCount
          );
          window.Mastodon.dispatchEvent(
            new SkipLazyLoadAdSlotEvent(
              skippedElement.element.id,
              skippedElement
            )
          );
        }
      }
      this.tryFillIndex += this.elementSkipCount + 1;

      this.elementSkipCount++;
    } else {
      this.elementSkipCount = 1; // reset the skip count after we get fill
      this.tryFillIndex++;
      lle.isFilled = true;
    }
    // try to render next lazy load ad
    if (this.elementList.length > this.tryFillIndex) {
      if (this.elementList[this.tryFillIndex].isVisible) {
        this.loadNextAd();
      } else {
      }
    } else {
      Logger.log("## lazy load list completed");
    }
  }

  loadNextAd() {
    this.isLoadingAd = true;
    const el = this.elementList[this.tryFillIndex];
    Logger.log(
      "## Trying to get ad for next lazy load element",
      el,
      this.tryFillIndex
    );
    el.canTryRender = true;
    DomAdManager.searchForNewAdContainers();
  }

  onIntersectionObserved(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) {
    let hasNewVisible = false;
    entries.forEach((entry) => {
      const { target, isIntersecting } = entry;
      const ve = this.elementMap.get(target.id);
      if (ve.isVisible) return;
      ve.isVisible = isIntersecting;
      if (isIntersecting) {
        Logger.log("Marking lazy-load element as visible", target);
        hasNewVisible = true;
      }
    });
    // Logger.log(
    //   "## seeing if we can render newly visible ad",
    //   hasNewVisible,
    //   this.elementList.length,
    //   this.tryFillIndex,
    //   this.elementList
    // );
    if (
      hasNewVisible &&
      !this.isLoadingAd &&
      this.elementList.length > this.tryFillIndex
    ) {
      this.loadNextAd();
    }
  }

  registerElement(element: HTMLElement) {
    if (this.elementMap.has(element.id)) {
      return;
    }

    const ve = new LazyLoadElement(element);
    this.elementMap.set(element.id, ve);
    this.elementList.push(ve);
    this.observer.observe(element);
  }

  shouldLoadAd(element: HTMLElement): boolean {
    const ve = this.elementMap.get(element.id);
    return ve.isVisible && ve.canTryRender;
  }
}

export default new LazyLoadAdManager();
