import RuleType from "./RuleType";
import RuleAction from "./RuleAction";

export default class ElementRule {
  ruleType: RuleType;
  action: RuleAction;
  elementSelector: string;

  constructor() {}

  async isApplicable(): Promise<boolean> {
    return await this.ruleType.isApplicable();
  }

  perform() {
    this.action.perform();
  }
}
