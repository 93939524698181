import GoogletagManager from "./GoogletagManager";
import { SlotUnion } from "./Types";

export const getSlotContainerID = (slot: SlotUnion): string => {
  if (typeof slot == "object") {
    return slot.getSlotElementId();
  } else {
    return slot;
  }
};

export const getSlotContainerIDs = (slot: SlotUnion[]): string[] => {
  return slot?.map((s) => getSlotContainerID(s));
};

export const getSlotObject = (slot: SlotUnion): googletag.Slot => {
  if (typeof slot == "string")
    return GoogletagManager.containerSlotMap[slot].slot;
  else return slot;
};

export const getSlotObjects = (slots: SlotUnion[]): googletag.Slot[] => {
  return slots.map((slot) => getSlotObject(slot));
};

export const cdnPath = (path) => `//${process.env.ASSET_CDN}${path}`;

/**
 * Converts a hex string to base64
 */
export function hex2base64(hex: string) {
  // convert hex to bytes
  let bytes = new Uint8Array(
    hex.match(/[\da-f]{2}/gi).map((h) => parseInt(h, 16))
  );
  // convert bytes to base64 web-safe
  return Buffer.from(bytes).toString("base64");
}
