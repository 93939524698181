import ConfigManager from "./ConfigManager";
import GeolocationRuleType from "./ElementRule/GeolocationRuleType";
import ElementRule from "./ElementRule";
import Logger from "./Logger";
import RuleType from "./ElementRule/RuleType";
import RuleAction from "./ElementRule/RuleAction";
import HideRuleAction from "./ElementRule/HideRuleAction";
import DisableAdsRuleAction from "./ElementRule/DisableAdsRuleAction";

class ElementRuleManager {
  rules: ElementRule[] = [];

  init() {
    Logger.log("ElementRuleManager init");
    var configs = ConfigManager.getElementRules();
    if (configs == null) {
      return;
    }

    configs.forEach((config) => {
      let rule = this.createElementRule(config);
      this.rules.push(rule);
    });
    this.performActions();
  }

  createElementRule(config) {
    let rule = new ElementRule();
    rule.elementSelector = config.element_selector;
    let ruleType = this.getRuleForType(config.type, config.parameters);
    rule.ruleType = ruleType;
    ruleType.rule = rule;
    rule.action = this.getActionForType(config.action);
    rule.action.rule = rule;
    return rule;
  }

  getRuleForType(type: string, params: any): RuleType | null {
    switch (type) {
      case "geolocation":
        return new GeolocationRuleType(params);
      default:
        Logger.error("Unknown rule type", type);
        return null;
    }
  }

  getActionForType(type: string): RuleAction | null {
    switch (type) {
      case "hide":
        return new HideRuleAction();
      case "disableAds":
        return new DisableAdsRuleAction();
      default:
        Logger.error("Unknown rule action", type);
        return null;
    }
  }

  performActions() {
    Logger.log("ElementRuleManager performActions");
    this.rules.forEach(async (rule) => {
      if (await rule.isApplicable()) {
        rule.perform();
      }
    });
  }
}

export default new ElementRuleManager();
