import Auction from ".";
import Logger from "../Logger";

import PrebidManager from "../PrebidManager";
import { AmazonAdapterConfig, BidBackHandler } from "../Types";
import AuctionAdapter from "./AuctionAdapter";
import apstag from "../apstag";

export default class PrebidAuctionAdapter extends AuctionAdapter {
  bidsBackHandler: BidBackHandler;

  constructor(auction: Auction, config: AmazonAdapterConfig) {
    super(auction, config, "amazon");
  }

  getBids(slots, containers): void {
    Logger.log("Amazon - adapter starting", slots, containers);

    if (apstag()?.fetchBids) {
      Logger.log("Amazon - Fetching bids");
      apstag().fetchBids(
        {
          slots: slots,
          timeout: 1500,
        },
        (bids) => {
          Logger.log("Amazon - received bids", bids);
          apstag().setDisplayBids();
          this.complete();
        }
      );
    } else {
      Logger.log("Amazon - apstag not present. Skipping amazon");
      this.complete();
    }
  }
}
