/**
 * Manages and supports finding ad containers in the dom
 */

import AdRefreshManager from "./AdRefreshManager";
import ContainerSizeManager, { ContainerSize } from "./ContainerSizeManager";
import GoogletagManager from "./GoogletagManager";
import Logger from "./Logger";
import TargetingManager from "./TargetingManager";
import { SlotUnion } from "./Types";
import { getSlotContainerIDs } from "./Utils";
import BlacklistManager from "./BlacklistManager";
import ConfigManager from "./ConfigManager";
import LazyLoadAdManager from "./LazyLoadAdManager";

class DomAdManager {
  adElements: Map<string, HTMLElement> = new Map();
  mutationObservers: Map<string, MutationObserver> = new Map();
  elementIdCounter: number = 0;
  domElementProcessor: (element: HTMLElement) => boolean = null;
  checkingContainers: boolean = false;
  hasCheckWaiting: boolean = false;

  /**
   * Searches the DOM for new mastodon adunits with the `data-mastodon-adunit` attribute
   * and shows ads for them.
   */
  async searchForNewAdContainers() {
    if (this.checkingContainers) {
      this.hasCheckWaiting = true;
      Logger.log("Already checking for new ad containers. Waiting...");
      return;
    }
    this.checkingContainers = true;
    this.checkForDeletedSlots();
    Logger.log("Seaching for new ad containers");
    let elements = document.querySelectorAll("[data-mastodon-adunit]");
    const newAdContainers: Array<string> = [];

    if (BlacklistManager.isUrlBlacklisted()) {
      Logger.log("Url is blacklisted. Removing all ad slots.");
      this.removeSlots();
      this.checkingContainers = false;
      return;
    }
    for (var element of Array.from(elements)) {
      let e = element as HTMLElement;
      if (this.isContainerHidden(e)) {
        Logger.log("Skipping invisible container", e);
        continue;
      }

      const { dataset } = e;
      const {
        mastodonAdunit,
        mastodonWidth,
        mastodonTargeting,
        mastodonRefresh,
      } = dataset;

      if (ConfigManager.isAdunitDisabled(mastodonAdunit)) {
        Logger.log("Skipping disabled ad unit", mastodonAdunit);
        continue;
      }

      if (!e.id) {
        e.id = `mastodon_${mastodonAdunit}-${this.elementIdCounter++}`;
      }

      if (ConfigManager.isAdunitLazyLoad(mastodonAdunit)) {
        LazyLoadAdManager.registerElement(e);

        //skip lazy-loaded elements that aren't ready for loading yet
        if (!LazyLoadAdManager.shouldLoadAd(e)) {
          continue;
        }
      }

      if (!this.adElements.has(e.id)) {
        Logger.log("Found new ad element", e, dataset);

        // Call dom element handler if available
        try {
          if (this.domElementProcessor) {
            let ret = this.domElementProcessor(e);
            if (ret === false) {
              Logger.log(
                "Skipping element due to processor returning false",
                e
              );
              continue;
            }
          }
        } catch (error) {
          Logger.error("Caught error in dom element processor", error);
        }

        this.adElements.set(e.id, e);
        let slot;

        if (mastodonWidth) {
          ContainerSizeManager.setContainerSize(
            e.id,
            new ContainerSize(parseInt(mastodonWidth), e.clientHeight)
          );
        } else if (e.clientWidth != 0) {
          ContainerSizeManager.setContainerSize(
            e.id,
            new ContainerSize(e.clientWidth, e.clientHeight)
          );
        }

        this.setupMutationObserver(e);

        if (!Array.isArray(window.Mastodon)) {
          slot = await window.Mastodon.addSlot(mastodonAdunit, e.id);
          Logger.log("Added slot", slot);
        }
        GoogletagManager.display(slot);
        newAdContainers.push(e.id);

        const targeting = this.parseTargeting(mastodonTargeting);
        Logger.log("parsed targeting", targeting);
        TargetingManager.addSlotTargeting(e.id, targeting);

        if (mastodonRefresh && mastodonRefresh != "0") {
          const timeoutMs = parseInt(mastodonRefresh);
          if (!timeoutMs)
            Logger.error(
              "Got falsy refresh value for ad slot",
              e.id,
              e.dataset,
              e
            );
          Logger.log("Adding refreh timer to slot", e.id, timeoutMs);
          AdRefreshManager.addSlot(e.id, timeoutMs);
        } else if (mastodonRefresh == "0") {
          AdRefreshManager.removeSlot(e.id);
        }
      }
    }

    if (newAdContainers.length > 0) {
      Logger.log("Starting auction for new ad containers");
      if (!Array.isArray(window.Mastodon))
        window.Mastodon.loadAds(newAdContainers);
    }
    //this.checkForDeletedSlots();
    this.checkingContainers = false;
    if (this.hasCheckWaiting) {
      this.hasCheckWaiting = false;
      this.searchForNewAdContainers();
    }
  }

  setupMutationObserver(element) {
    const observer = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "attributes") {
          if (mutation.attributeName == "data-mastodon-width") {
            if (
              !Array.isArray(window.Mastodon) &&
              ContainerSizeManager.getContainerSize(element.id).width !=
                parseInt(element.dataset["mastodonWidth"])
            ) {
              this.resetSizeOfContainer(
                element,
                parseInt(element.dataset["mastodonWidth"])
              );
            }
          }
        }
      }
    });
    observer.observe(element, {
      attributes: true,
      attributeFilter: ["data-mastodon-width"],
    });
    this.mutationObservers.set(element.id, observer);
  }

  resetSizeOfContainer(element, newWidth) {
    Logger.log("Detected new size for container ", element.id, newWidth);
    let didReset = false;
    if (!Array.isArray(window.Mastodon)) {
      didReset = window.Mastodon.setSlotSizeAndReset(
        element.id,
        newWidth,
        element.clientHeight
      );
    }
    if (didReset) {
      setTimeout(() => {
        if (!Array.isArray(window.Mastodon)) {
          window.Mastodon.searchForNewAdContainers();
        }
      }, 100);
    }
  }

  clearElements() {
    this.adElements.clear();
  }

  checkForDeletedSlots() {
    Logger.log("Checking for deleted slots");
    const slotsToDestroy = [];
    [...this.adElements.keys()].forEach((key) => {
      if (!document.getElementById(key)) {
        Logger.log(`Slot ${key} no longer exists. Destroying.`);
        const slot = GoogletagManager.getSlot(key);
        slotsToDestroy.push(slot);
      }
    });
    if (slotsToDestroy.length > 0) {
      if (!Array.isArray(window.Mastodon)) {
        Logger.log(`Found missing slots. Destroying:`, slotsToDestroy);
        window.Mastodon.destroySlots(slotsToDestroy, {
          removeFromDom: false,
          clearRefresh: true,
        });
      }
    } else {
      Logger.log("No slots to destroy");
    }
  }

  /**
   * removes tracking of an adslot and removes it from the DOM
   * If no slots provided it removes all tracked slots
   * @param slots
   */
  removeSlots(slots?: SlotUnion[], removeFromDom = true) {
    let containerIds, elements;
    if (slots) {
      containerIds = getSlotContainerIDs(slots);
      Logger.log(this.adElements);
      elements = containerIds.map((c) => this.adElements.get(c));
    } else {
      containerIds = [...this.adElements.keys()];
      elements = [...this.adElements.values()];
    }

    Logger.log("Removing slots from dom management", containerIds, elements);
    if (removeFromDom) {
      elements.forEach((e) => {
        if (e) e.remove();
      });
    }

    containerIds.forEach((k) => this.adElements.delete(k));
    containerIds.forEach((k) => this.mutationObservers.delete(k));
  }

  getManagedSlots() {
    Logger.log("getManagedSlots");
    const array = [];
    return [...this.adElements.keys()];
  }

  parseTargeting(targeting: string) {
    if (!targeting) return {};

    const t = {};
    const keyValues = targeting.split(";");
    keyValues.forEach((kv) => {
      let [key, value] = kv.split("=");
      key = key.trim();
      if (key) t[key] = value.trim();
    });

    return t;
  }

  isContainerHidden(element: HTMLElement) {
    let levelCount = 0;
    let e = element;
    while (!!e && levelCount++ < 10) {
      const display = window.getComputedStyle(e).display;
      if (display == "none") return true;
      e = e.parentElement;
    }
    return false;
  }
}

export default new DomAdManager();
