import ElementRule from ".";

export default class RuleType {
  rule: ElementRule;
  parameters: any;
  constructor(pams: any) {
    this.parameters = pams;
  }

  async isApplicable(): Promise<boolean> {
    throw "Not implemented";
    return false;
  }
}
