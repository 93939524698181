import GeolocationManager from "../GeolocationManager";
import Logger from "../Logger";
import RuleType from "./RuleType";

interface GeoParams {
  country: string;
  state: string;
}

export default class GeolocationRuleType extends RuleType {
  parameters: GeoParams;

  constructor(params: GeoParams) {
    super(params);
  }

  async isApplicable(): Promise<boolean> {
    let geo = GeolocationManager.getGeoLocationData();

    // fallback to canonical geo data if otherwise unavailable
    if (!geo) {
      geo = await GeolocationManager.getCanonicalGeoData();
    }
    if (!geo) {
      Logger.error(
        "GeolocationRuleType.isApplicable no geo available!!!",
        this.parameters
      );
      return false;
    }

    if (this.parameters.country) {
      var regex = new RegExp(this.parameters.country, "i");
      if (!regex.test(geo.countryCode)) {
        Logger.log(
          "GeolocationRuleType.isApplicable false",
          this.parameters,
          geo
        );
        return false;
      }
    }
    if (this.parameters.state) {
      var regex = new RegExp(this.parameters.state, "i");

      if (!regex.test(geo.stateCode)) {
        Logger.log(
          "GeolocationRuleType.isApplicable false",
          this.parameters,
          geo
        );
        return false;
      }
    }
    Logger.log("GeolocationRuleType.isApplicable true", this.parameters, geo);
    return true;
  }
}
// Path: src/lib/ElementRule/RuleType.ts
